import React, { PureComponent } from 'react';
import Radium from 'radium';

import * as COLORS from 'src/constants/colors';
import { APP_NAME } from 'src/constants/general';
import { t } from 'src/modules/i18n';

import BG from './images/bg.png';
import LOGO from './images/logo.png';

class Header extends PureComponent {
  render() {
    return (
      <header style={styles.header}>
        <div style={styles.logo} />
        <div>
          <h1 style={styles.title}>{APP_NAME}</h1>
          <h2 style={styles.subtitle}>{t('HEADER.SUBTITLE')}</h2>
        </div>
      </header>
    );
  }
}

export default Radium(Header);

const styles = {
  header: {
    padding: '48px 8px 32px 32px',
    maxWidth: 375,
    width: '100%',
    height: 158,
    display: 'flex',
    backgroundImage: `url(${BG})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    flex: '0 0 158px',
  },
  logo: {
    flex: '0 0 66px',
    width: 66,
    height: 66,
    marginRight: 16,
    backgroundImage: `url(${LOGO})`,
    backgroundSize: 'contain',
  },
  title: {
    fontWeight: '900',
    fontSize: '28px',
    color: COLORS.WHITE,
    textShadow: '0 2px 6px rgba(26, 48, 74, 0.15)',
  },
  subtitle: {
    marginTop: 0,
    fontSize: '17px',
    lineHeight: '2',
    fontWeight: '800',
    color: COLORS.WHITE,
    textShadow: '0 2px 4px rgba(26, 48, 74, 0.15)',
  },
};
