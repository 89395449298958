import React, { PureComponent } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { downloadApp } from 'src/store/app/actions';
import { t } from 'src/modules/i18n';
import Analytics from 'src/modules/analytics';
import * as COLORS from 'src/constants/colors';
import Header from 'src/components/header';
import Button from 'src/components/button-with-animation';

const BLOCKS = {
  'courses': React.lazy(() => import('./components/courses')),
  'games': React.lazy(() => import('./components/games')),
  'sharp': React.lazy(() => import('./components/sharp')),
  'carousel': React.lazy(() => import('./components/carousel')),
};

class MainScreen extends PureComponent {
  componentDidMount() {
    Analytics.trackEvent('page', 'loaded');
  }

  handlePress = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Analytics.trackEvent('button', 'click');
    downloadApp();
  };

  handlePageClick = () => {
    const { pageClickMode } = this.props;
    if (pageClickMode) {
      Analytics.trackEvent('page', 'click');
      downloadApp();
    }
  };

  render() {
    const {
      btnTitle,
      block,
    } = this.props;

    const Block = BLOCKS[block];

    return (
      <div onClick={this.handlePageClick} style={styles.root}>
        <main style={styles.main}>
          <Header />
          <React.Suspense fallback={<div style={styles.suspence}><div className={'loader'} /></div>}>
            {Block && <Block />}
          </React.Suspense>
          <Button
            flare
            buttonStyle={styles.button}
            title={btnTitle}
            onClick={this.handlePress} />
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    remoteConfig: {
      btnTitle,
      pageClickMode,
      block,
    },
  } = state;

  return {
    pageClickMode,
    btnTitle: btnTitle || t('BUTTON_TITLE'),
    block,
  };
};
const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), Radium)(MainScreen);

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.VERY_LIGHT_PINK_2,
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "@media only screen and (max-height: 670px)": {
      padding: '0 0 12px 0',
    },
    padding: '0 0 24px 0',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'stretch',
  },
  button: {
    maxWidth: 320,
    marginTop: 12,
    zIndex: 10,
  },
  suspence: {
    flex: 1,
    display: 'flex',
  }
};
