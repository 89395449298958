import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { StyleRoot } from 'radium';

import Main from './main';

export default compose(
  connect(state => ({
    loaded: state.app.loaded,
  })),
)(({ loaded }) => {
  return loaded ? (
    <StyleRoot className="fill-available"> <Main /></StyleRoot>
  ) : null;
});
