import queryString from 'query-string'
import { createAction } from 'redux-actions';
import Attribution from '@wowmaking/web2app';

import Analytics from 'src/modules/analytics';
import { APPSFLYER_LINK, FIREBASE_LINK, BUNDLE_ID, APP_STORE_ID } from 'src/constants/general';

import { init as initRemoteConfig } from '../remote-config/actions';

import { SET_LOADED } from './types';

const setLoaded = createAction(SET_LOADED);

export function load() {
  return function (dispatch) {
    Attribution.init();
    return Analytics.init()
      .then(() => dispatch(initRemoteConfig()))
      .then(() => {
        document.querySelector('#loader').style.display = 'none';
        document.querySelector('#root').style.display = 'flex';
        dispatch(setLoaded());
      });
  };
}

export function downloadApp() {
  Analytics.trackEvent('install_app', 'click');
  let link = Attribution.addAttributionToLink(APPSFLYER_LINK);
  link = queryString.stringifyUrl({
    url: FIREBASE_LINK,
    query: {
      link,
      apn: BUNDLE_ID,
      ibi: BUNDLE_ID,
      isi: APP_STORE_ID,
      efr: 1,
    },
  });
  window.location.href = link;
}
