import React, { forwardRef, } from 'react';
import Radium from 'radium';
import { compose } from 'recompose';

import * as COLORS from 'src/constants/colors';

import Flare from './flare';

export default compose(
  Radium,
  forwardRef,
)(({
  onClick,
  title,
  subTitle,
  disabled,
  flare,
  buttonStyle,
  buttonTitleStyle,
  buttonSubtitleStyle,
  contentContainerStyle,
  children,
  iconRight,
  ...other
}, ref) => {
  return (
    <button
      {...other}
      ref={ref}
      onClick={onClick}
      style={[styles.button, buttonStyle, disabled ? styles.disabled : null]}
      disabled={disabled}
    >
      <div style={contentContainerStyle}>
        <p style={[styles.buttonTitle, buttonTitleStyle]}>{title}</p>
        {subTitle ? (
          <p style={[styles.buttonSubtitle, buttonSubtitleStyle]}>
            {subTitle}
          </p>
        ) : null}
        {iconRight}
        {!disabled && flare ?
          <Flare />
          : null}
      </div>
      {children}
    </button>
  );
})

const styles = {
  button: {
    background: `linear-gradient(to left, ${COLORS.DODGER_BLUE}, ${COLORS.BRIGHT_BLUE})`,
    border: '0',
    minHeight: 55,
    borderRadius: 30,
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    alignSelf: 'center',
    transitionProperty: 'opacity',
    ':focus': {
      outline: 0,
    },
    ':active': {
      opacity: 0.75,
    },
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
    boxShadow: `0 8px 8px ${COLORS.BRIGHT_BLUE}44`,
  },
  buttonTitle: {
    fontSize: '20px',
    fontWeight: '800',
    textAlign: 'center',
    color: COLORS.WHITE,
    textTransform: 'uppercase',
  },
  buttonSubtitle: {
    fontSize: 15,
    color: COLORS.WHITE,
  },
  disabled: {
    backgroundImage: COLORS.WHITE,
  },
};
